import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="webpush"
export default class extends Controller {
  static values = {
    vapid: String,
    csrf: String,
    pass: String
  };

  connect() {
    // this.registerServiceWorker();
    window.addEventListener("permission-ask", () => this.subscribeToPush());
  }

  async registerServiceWorker() {
    try {
      await navigator.serviceWorker.register("/service_worker.js", { scope: "/" });

      console.log("Service worker successfully registered.");
    } catch (error) {
      console.error(error);
      this.createGooglePass();
    }
  }

  async subscribeToPush() {
    console.log("Subscribe to push");
    try {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        const options = {
          userVisibleOnly: true,
          applicationServerKey: this.vapidValue
        };

        serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
          if (subscription) {
            console.log("User is already subscribed to push.");
            console.log(subscription);

            this.sendSubscriptionToBackEnd(subscription);
          } else {
            const newSubscription = serviceWorkerRegistration.pushManager
              .subscribe(options)
              .then((pushSubscription) => {
                console.log("Created new subscription to push.");
                console.log(pushSubscription);

                this.sendSubscriptionToBackEnd(pushSubscription);
              });

            // Race between the subscription and the timeout
            this.promiseTimeout(7000, newSubscription).catch((error) => {
              console.error(error);

              this.createGooglePass();
            });
          }
        });
      });
    } catch (error) {
      console.error(error);

      this.createGooglePass();
    }
  }

  promiseTimeout(ms, promise) {
    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise((_, reject) => {
      let id = setTimeout(() => {
        clearTimeout(id);
        reject("Push permission request timed out in " + ms + "ms.");
      }, ms);
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeout]);
  }
  async sendSubscriptionToBackEnd(subscription) {
    try {
      const response = await fetch(`/subscribe_webpush/${this.passValue}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.csrfValue
        },
        body: JSON.stringify(subscription)
      });
      if (!response.ok) {
        throw new Error("Bad status code from server.");
      } else {
        console.log("Sent subscription to back end");

        this.createGooglePass();
      }
    } catch (error) {
      console.error(error);

      this.createGooglePass();
    }
  }

  createGooglePass() {
    console.log("Create google pass");
    const customEvent = new CustomEvent("create-google-pass");
    window.dispatchEvent(customEvent);
  }
}
