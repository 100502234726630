import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="pass-download"
export default class extends Controller {
  static targets = [
    "qr",
    "googleButton",
    "appleButton",
    "loader",
    "header",
    "container",
    "successDiv",
    "notificationLoader"
  ];

  static values = {
    pass: String,
    csrf: String,
    googleSuccess: String,
    appleSuccess: String,
    googleFailure: String,
    appleFailure: String,
    appleSuccessText: String,
    googleSuccessText: String,
    generalError: String,
    notFound: String,
    alreadyAddedGoogle: String,
    alreadyAddedApple: String,
    appleButtonText: String,
    googleButtonText: String,
    skipPreview: String,
    facingIssues: String,
    tryAgain: String
  };

  connect() {
    window.addEventListener("load", (event) => {
      this.getMobileOS();
    });
  }

  async getMobileOS() {
    const ua = navigator.userAgent;
    if (this.skipPreviewValue === "true") {
      if (/android/i.test(ua)) {
        this.googleClick();
      } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) {
        this.createApplePass();
      } else {
        this.displayQR();
      }
    } else {
      if (/android/i.test(ua)) {
        this.displayGoogleButton();
      } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) {
        this.displayAppleButton();
      } else {
        this.displayQR();
      }
    }
  }

  displayQR() {
    this.qrTarget.classList.toggle("hidden");
  }

  displayGoogleButton() {
    this.googleButtonTarget.classList.toggle("hidden");
  }

  displayAppleButton() {
    this.appleButtonTarget.classList.toggle("hidden");
  }

  googleClick() {
    this.googleButtonTarget.classList.add("hidden");
    this.createGooglePass();
    // this.displayNotificationLoader();

    // // Call to webpushController
    // const customEvent = new CustomEvent("permission-ask");
    // window.dispatchEvent(customEvent);

    // // Create google pass after push subscription
    // window.addEventListener("create-google-pass", () => this.createGooglePass());
  }

  appleClick() {
    this.appleButtonTarget.classList.toggle("hidden");
    this.createApplePass();
  }

  displayLoader() {
    this.loaderTarget.classList.toggle("hidden");
  }

  displayNotificationLoader() {
    this.notificationLoaderTarget.classList.toggle("hidden");
  }

  createGooglePass() {
    this.displayLoader();

    console.log("API call to create Google Pass");
    fetch(`/create_google_pass/${this.passValue}`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.csrfValue
      }
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 409) {
            throw new Error(this.alreadyAddedGoogleValue);
          } else if (response.status === 404) {
            throw new Error(this.notFoundValue);
          } else {
            throw new Error(this.generalErrorValue);
          }
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // Redirect to Google Wallet download link
        const downloadLink = `https://pay.google.com/gp/v/save/${data.jwt}`;
        window.location.assign(downloadLink);
      })
      .catch((error) => {
        console.error(error);
        this.googleFailureHandler(error.message);
      });
  }

  async createApplePass() {
    this.displayLoader();

    const response = await fetch(`/create_apple_pass/${this.passValue}`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.csrfValue
      }
    });

    if (!response.ok) {
      if (response.status === 409) {
        this.appleFailureHandler(this.alreadyAddedAppleValue);
      } else if (response.status === 404) {
        this.appleFailureHandler(this.notFoundValue);
      } else {
        this.appleFailureHandler(this.generalErrorValue);
      }
      return;
    }

    const blob = await response.blob();
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "pass.pkpass";
    this.element.appendChild(downloadLink);
    this.appleSuccessHandler();

    setTimeout(() => {
      downloadLink.click();
    }, 500);

    window.addEventListener("focus", (event) => {
      // Display the success message when the screen is back in focus
      this.successDivTarget.style.visibility = "visible";
    });

    setTimeout(() => {
      this.successDivTarget.style.visibility = "visible";
    }, 5000);
  }

  googleSuccessHandler() {
    this.containerTarget.remove();

    const googleSuccess = document.createElement("div");
    googleSuccess.setAttribute("class", "success");

    const googleSuccessText = document.createElement("h2");
    googleSuccessText.innerHTML = this.googleSuccessTextValue;

    const googleSuccessImage = document.createElement("img");
    googleSuccessImage.setAttribute("src", this.googleSuccessValue);

    const googleWalletDeeplink = document.createElement("a");
    googleWalletDeeplink.innerHTML = this.googleButtonTextValue;
    googleWalletDeeplink.setAttribute(
      "href",
      "intent://wallet.app.google/#Intent;scheme=https;package=com.google.android.apps.walletnfcrel;end"
    );
    googleWalletDeeplink.setAttribute("class", "deeplink-button");

    googleSuccess.appendChild(googleSuccessImage);
    googleSuccess.appendChild(googleSuccessText);
    googleSuccess.appendChild(googleWalletDeeplink);

    this.element.appendChild(googleSuccess);
  }

  googleFailureHandler(errorMessage) {
    this.containerTarget.remove();

    const googleFailure = document.createElement("div");
    googleFailure.setAttribute("class", "success");

    const googleFailureText = document.createElement("h2");
    googleFailureText.innerHTML = errorMessage;

    const googleFailureImage = document.createElement("img");
    googleFailureImage.setAttribute("src", this.googleFailureValue);

    googleFailure.appendChild(googleFailureImage);
    googleFailure.appendChild(googleFailureText);

    this.element.appendChild(googleFailure);
    googleFailure.style.visibility = "visible";
  }

  tryAgain() {
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  appleSuccessHandler() {
    this.containerTarget.remove();

    const appleSuccess = document.createElement("div");
    appleSuccess.setAttribute("class", "success");
    appleSuccess.setAttribute("data-pass-download-target", "successDiv");

    const appleSuccessText = document.createElement("h2");
    appleSuccessText.innerHTML = this.appleSuccessTextValue;

    const appleSuccessImage = document.createElement("img");
    appleSuccessImage.setAttribute("src", this.appleSuccessValue);

    const appleWalletDeeplink = document.createElement("a");
    appleWalletDeeplink.innerHTML = this.appleButtonTextValue;
    appleWalletDeeplink.setAttribute("href", "wallet://");
    appleWalletDeeplink.setAttribute("class", "deeplink-button");

    const tryAgainDiv = document.createElement("div");
    tryAgainDiv.setAttribute("class", "try-again-container");

    const issuesText = document.createElement("h3");
    issuesText.innerHTML = this.facingIssuesValue;
    issuesText.setAttribute("class", "issues-text");

    const tryAgainLink = document.createElement("a");
    tryAgainLink.innerHTML = this.tryAgainValue;
    tryAgainLink.setAttribute("data-action", "click->pass-download#tryAgain");
    tryAgainLink.setAttribute("class", "try-again-button");

    tryAgainDiv.appendChild(issuesText);
    tryAgainDiv.appendChild(tryAgainLink);

    appleSuccess.appendChild(appleSuccessImage);
    appleSuccess.appendChild(appleSuccessText);
    appleSuccess.appendChild(appleWalletDeeplink);
    appleSuccess.appendChild(tryAgainDiv);

    this.element.appendChild(appleSuccess);
  }

  appleFailureHandler(errorMessage) {
    this.containerTarget.remove();

    const appleFailure = document.createElement("div");
    appleFailure.setAttribute("class", "success");

    const appleFailureText = document.createElement("h2");
    appleFailureText.innerHTML = errorMessage;

    const appleFailureImage = document.createElement("img");
    appleFailureImage.setAttribute("src", this.appleFailureValue);

    appleFailure.appendChild(appleFailureImage);
    appleFailure.appendChild(appleFailureText);

    this.element.appendChild(appleFailure);
    appleFailure.style.visibility = "visible";
  }
}
